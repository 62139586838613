import React, { useEffect, useState } from "react";
import ReportBugHeader from "./ReportBugHeader";
import { message, Upload, Input, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PText, SecStartedContent } from "../../../styleComponents";
import { Helmet } from "react-helmet";

import CloudArrowUp from "../../../new/icons/CloudArrowUp.svg";
import { showSuccessNotification, showErrorNotification } from "./ShowUpdate";
import { MessageIcon } from "../../../images/MessageIcon";
import { BaseAPI, LocalHost } from "../../../../globalConstants";
import TokenHandler from "../../../../token";
import { attachmentHelper } from "./helper";
import LocalDb from "../../../../localStorage";
import { ModalWrapper, StyledModal } from "./style";
import { Waypoint } from "react-waypoint";

const ReportBug = ({ handleWaypointEnter, handleWaypointLeave }) => {
  const [attachments, setAttachments] = useState([]);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { TextArea } = Input;
  const { Dragger } = Upload;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 14,
      }}
      spin
    />
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchImgData = async (formData, info, dataList) => {
    const list = [];

    dataList &&
      dataList.length > 0 &&
      dataList.forEach((single) => {
        if (single.uid === info.uid) {
          const obj = {
            ...single,
            loading: true,
          };
          list.push(obj);
        } else {
          list.push(single);
        }
      });

    setData(list);
    setLoading(true);
    console.log("ABCD-formData", formData);
    fetch(BaseAPI + "anydone-file/upload/file", {
      method: "POST",
      headers: TokenHandler.authHeadersForMultipartFormData(),
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("ABCD ssssss1", res);
        if (res.error === true) {
          showErrorNotification("error", res.msg);
          setLoading(false);
        } else {
          const fileObj = {
            title: res.stringValue,
            id: info.uid,
            url: res.fileUrl,
            type: "IMAGE_TYPE",
          };
          setAttachments([...attachments, fileObj]);
          const listOfData = [...dataList];
          const list = [];
          listOfData &&
            listOfData.forEach((single) => {
              if (single.uid === info.uid) {
                const obj = {
                  ...single,
                  name: res.stringValue,
                  url: res.fileUrl,
                };
                list.push(obj);
              } else {
                list.push(single);
              }
            });
          setData(list);
          setLoading(false);
        }
        return res;
      })
      .catch((error) => {
        setLoading(false);
        setData([]);
        showErrorNotification("error", error);
      });
  };

  const sendReport = (formDetails) => {
    fetch(`${BaseAPI}co-anydone/v1/bug/report`, {
      method: "POST",
      headers: TokenHandler.authHeaders(),
      body: JSON.stringify(formDetails),
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.response) {
          showSuccessNotification(data.response.msg);
          setTitle("");
          setDescription("");
          setAttachments([]);
          setData([]);
        } else {
          showErrorNotification(data.msg);
        }
      })
      .catch((err) => {
        err && showErrorNotification("Internal error");
      });
  };

  const props = {
    name: "file",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      console.log("ABCD info", info);

      if (status === "done") {
        const arrayList = [];
        arrayList.push(...data, info.file.originFileObj);

        const list = arrayList.map((single) => ({
          ...single,
          name: single.name,
          loading: false,
        }));
        setData(list);

        const formData = new FormData();
        formData.append("file", info.file.originFileObj);
        console.log("ABCD-formData2", list, formData);

        // Call the API to upload the file
        fetchImgData(formData, info.file.originFileObj, list);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleRemove = (uid) => {
    const filterData =
      data && data.length > 0 && data.filter((each) => each.uid !== uid);
    const filterAttachments =
      attachments &&
      attachments.length > 0 &&
      attachments.filter((single) => single.id !== uid);

    setData(filterData);

    setAttachments(filterAttachments);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleSubmit = () => {
    // console.log(LocalDb.getSessions(), "session");
    if (LocalDb.getSessions() !== null) {
      const attachmentsList = attachmentHelper(attachments);
      const formDetails = {
        title,
        description,
        attachments: attachmentsList || [],
      };
      // console.log(formDetails, "HGHGHG form details");
      sendReport(formDetails);
    } else {
      setOpenModal(true);
    }
  };
  console.log("ABCD data", data);
  return (
    <>
      <div>
        <Helmet>
          <title>Report Bug</title>
          <meta
            name="description"
            content="If you found anything wrong within this platform, Feel free to report it to us. We will continuously improve ourself."
          />
        </Helmet>

        <ReportBugHeader />
        <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />

        <SecStartedContent>
          <div className="inner-box">
            <div className="bug-each_div_input">
              <label>
                <PText>
                  Bug Title
                  <span style={{ color: "red" }}>*</span>
                </PText>
              </label>
              <Input
                placeholder="Enter Bug Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="input_field"
              />
            </div>

            <div className="bug-each_div_input">
              <label>
                <PText>
                  Description
                  <span style={{ color: "red" }}>*</span>
                </PText>
              </label>
              <TextArea
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Explain about the bug"
                className="input_field"
              />
            </div>
            <div className="bug-each_div_input">
              <label>
                <PText>Attachment</PText>
              </label>
              <Dragger
                {...props}
                customRequest={dummyRequest}
                showUploadList={false}
                className="input_field"
                accept=".png,.jpeg"
                // beforeUpload={CheckFiletype}
              >
                <img src={CloudArrowUp} alt="CloudArrowUp-icon" />
                <PText className="ant-upload-text">Drag & Drop</PText>
                <PText className="ant-upload-hint">
                  Your files here or <span>Browse here</span>
                </PText>
              </Dragger>
              <div
                style={{ display: "flex", gap: "5px" }}
                hidden={attachments.length !== 0}
              >
                <span>Note</span>
                <span style={{ color: "red" }}>*</span>
                <p>Only png and jpeg file types are allowed.</p>
              </div>
              {data.length >= 1 && (
                <div className="upload-preview-dashed">
                  {data.map((each) => {
                    return (
                      <div className="each-upload">
                        <div className="img-name-side">
                          {each.loading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            <img
                              src={each.url}
                              className="preview-img"
                              alt="-"
                            />
                          )}

                          <PText>{each.name}</PText>
                        </div>
                        <div
                          className="icon"
                          onClick={() => handleRemove(each.uid)}
                        >
                          <MessageIcon.FilledCloseIcon />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                className="bug_sentbtn"
                onClick={handleSubmit}
                disabled={title === "" || description === "" || loading}
              >
                <b>Submit</b>
              </Button>
            </div>
          </div>
        </SecStartedContent>
      </div>
      <StyledModal
        open={openModal}
        centered
        title={
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>Sign In</span>
        }
        closable={false}
        okText={"Sign In"}
        onCancel={() => setOpenModal(false)}
        width={400}
        onOk={() =>
          window.location.replace(`${LocalHost}login?path=from_help_desk`)
        }
      >
        <ModalWrapper>
          <p>You need to sign in to report a bug.</p>
        </ModalWrapper>
      </StyledModal>
    </>
  );
};

export default ReportBug;
